<template>
    <v-row class="ma-0">
        <v-col cols="12" class="pl-0">
            <v-chip color="primary" label text-color="white">
                <v-icon left> mdi-label</v-icon>Vehicle Surcharges
            </v-chip>
        </v-col>
        <slot></slot>
        <v-col cols="12" lg="4" xl="4" md="4">
            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Metalic</v-subheader>
            <v-text-field v-model="model.sc_metallic" append-icon="mdi-percent-outline" placeholder="Add Metalic %"
                outlined ref="sc_metallic" type="number" min="0" hide-details="auto"/>
        </v-col>
        <v-col cols="12" lg="4" xl="4" md="4">
            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Pearl</v-subheader>
            <v-text-field v-model="model.sc_pearl" append-icon="mdi-percent-outline" placeholder="Add Pearl %"
                outlined ref="sc_pearl" type="number" min="0" hide-details="auto"/>
        </v-col>
        <v-col cols="12" lg="4" xl="4" md="4">
            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Include P2P</v-subheader>
            <v-radio-group class="ml-4" v-model="model.sc_p2p" ref="sc_p2p" row hide-details="auto">
                    <v-radio label="Yes" :value="1"></v-radio>
                    <v-radio label="No" :value="0"></v-radio>
            </v-radio-group>
        </v-col>
        <v-col cols="12" class="pl-0 mt-2">
            <v-chip color="primary" label text-color="white">
                <v-icon left> mdi-label</v-icon>Average Calculation method
            </v-chip>
            <v-radio-group v-model="model.calculation_method" class="mt-5 ml-4" ref="calculation_method" row hide-details="auto">
                    <v-radio label="Threshold" :value="'T'"></v-radio>
                    <v-radio label="Weight" :value="'W'"></v-radio>
            </v-radio-group>
            <div v-if="model.calculation_method == 'T'" class="mt-5 ml-4">
                <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Severity Threshold</v-subheader>
                <v-text-field v-model="model.sc_threshold" min="0" type="number" placeholder="Add Severity Threshold" outlined
                    ref="sc_threshold" hide-details="auto"/>
            </div>
            <v-card class="mt-5 ml-4" v-if="model.calculation_method == 'W'">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr class="primary">
                                <th class="text-center text-uppercase text-white text-center"
                                    v-for="title in calculationHeader" :key="title">{{title}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center pa-0 pa-3" v-for="(item, index) in calculationHeader" :key="item+index">
                                    <v-text-field class="box-shadow-none input-align-center px-0" type="number" :ref="item" outlined dense min="0"
                                        v-model.number="model.weighted_no[item]" hide-details="auto"/>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: ['model', 'v'],
    data() {
        return {
            calculationHeader: ['oversize', 'severe', 'heavy', 'medium', 'light']
        }
    }
}
</script>

<style>

</style>